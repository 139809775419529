import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35ab20a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "LatestStories"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.stories)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stories, (item) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            to: item.link,
            key: item.id,
            class: "LatestStories__item"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: "LatestStories__item-title",
                innerHTML: item.title
              }, null, 8, _hoisted_2),
              _createElementVNode("span", {
                class: "LatestStories__item-date",
                innerHTML: item.date
              }, null, 8, _hoisted_3)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, " Loading... "))
}