import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/HomeView.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/AboutView.vue')
    },
    {
        path: '/stories',
        name: 'Stories',
        component: () => import('../views/StoriesView.vue')
    },
    {
        path: '/stories/:code',
        name: 'StoriesCategory',
        component: () => import('../views/StoriesCategoryView.vue')
    },
    {
        path: '/story/:code',
        name: 'Story',
        component: () => import('../views/StoryView.vue')
    },
    {
        path: '/hello',
        name: 'Hello',
        component: () => import('../views/HelloView.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../views/NotFoundView.vue')
    },
    {
        path: '/search',
        name: 'Search',
        component: () => import('../views/SearchView.vue')
    },
    {
        path: '/tools',
        name: 'Tools',
        component: () => import('../views/ToolsView.vue')
    },
]

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        return {top: 0}
    },
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
