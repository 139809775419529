

import {defineComponent} from 'vue';
import HeaderLayout from "@/components/layouts/HeaderLayout.vue";
import FooterLayout from "@/components/layouts/FooterLayout.vue";
import AppService from "@/services/AppService";
import SidebarLayout from "@/components/layouts/SidebarLayout.vue";

export default defineComponent({
  name: 'App',
  components: {SidebarLayout, FooterLayout, HeaderLayout},
  data: function () {
    return {
      showSidebar: true
    };
  },
  created: function () {

    this.loadApp();

  },
  methods: {
    loadApp() {
      const App = AppService.loadApp();
    }
  },
  watch: {
    $route: function (to) {

      if (to.name == 'About' || to.name == 'Search') {
        this.showSidebar = false;
      } else {
        this.showSidebar = true;
      }

    }
  }
});

