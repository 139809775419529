import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-152242da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-button" }
const _hoisted_2 = {
  class: "btn btn-primary",
  type: "submit"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", _hoisted_2, [
      _createElementVNode("span", null, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.icon,
            alt: ""
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ])
  ]))
}