
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'HeaderLayout',
  methods: {
    isNewYearHappy() {

      const date = new Date();
      const month = date.getMonth();

      return month === 11 || month === 0;

    }
  }
});
