import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "page__content" }
const _hoisted_2 = {
  key: 0,
  class: "page__sidebar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_layout = _resolveComponent("header-layout")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SidebarLayout = _resolveComponent("SidebarLayout")!
  const _component_footer_layout = _resolveComponent("footer-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_layout),
    _createElementVNode("div", {
      class: _normalizeClass(["page", { 'page_sidebar': _ctx.showSidebar }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_view)
      ]),
      (_ctx.showSidebar)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_SidebarLayout)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_component_footer_layout)
  ], 64))
}