
import {defineComponent} from 'vue';
import UiButton from "@/components/ui/UiButton.vue";

export default defineComponent({
  name: 'SearchWidget',
  components: {UiButton},
  props: ['searchQueryValue'],
  data: function () {
    return {
      searchQuery: ''
    }
  },
  created: function () {
    this.setSearchQueryValue();
  },
  methods: {
    sendSearchForm() {
      this.$router.push('/search?q=' + this.searchQuery);
    },
    setSearchQueryValue() {

      if (!this.searchQueryValue) {
        return false;
      }

      this.searchQuery = this.searchQueryValue;
    }
  }
});
