import BaseApi from "@/api/BaseApi";

class StoriesApi extends BaseApi {

    public static async getStories(category: string | string[]) {
        return await this.request('/get-stories', {category: category});
    }

    public static async getLatestStories() {
        return await this.request('/get-latest-stories', {});
    }

}

export default StoriesApi;
