import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createElementBlock("form", {
    class: "search-widget",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.sendSearchForm()), ["prevent"]))
  }, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
      type: "search",
      class: "form-control search-widget__input",
      placeholder: "Введи и найди"
    }, null, 512), [
      [_vModelText, _ctx.searchQuery]
    ]),
    _createVNode(_component_ui_button, { class: "search-widget__button" }, {
      default: _withCtx(() => [
        _createTextVNode("Поиск")
      ]),
      _: 1
    })
  ], 32))
}