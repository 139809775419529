import BaseApi from "@/api/BaseApi";

class AppApi extends BaseApi {

    public static async loadApp() {
        return await this.request('/load-app', []);
    }

}

export default AppApi;
