import axios from "axios";

class BaseApi {

    protected static async request(endpoint: string, data: object) {

        axios.defaults.baseURL = 'https://drobot.dev/api';
        axios.defaults.headers.common['Authorization'] = '';

        const res = await axios.post(endpoint, data);

        return res.data;
    }


}

export default BaseApi;
