
import {defineComponent} from 'vue';
import StoriesService from "@/services/StoriesService";

export default defineComponent({
  name: 'LatestStories',
  props: [],
  data() {
    return {
      'stories': false
    }
  },
  created() {
    this.loadLatestStories();
  },
  methods: {
    async loadLatestStories() {

      const stories = await StoriesService.getLatestStories();

      this.stories = stories.items;

    }
  }
});
