import BasicService from "@/services/BasicService";
import StoriesApi from "@/api/StoriesApi";

class StoriesService extends BasicService {

    public static async getStories(category: string | string[]) {
        return await StoriesApi.getStories(category);
    }

    public static async getLatestStories() {
        return await StoriesApi.getLatestStories();
    }

}

export default StoriesService;
