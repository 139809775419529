import BasicService from "@/services/BasicService";
import AppApi from "@/api/AppApi";

class AppService extends BasicService {

    public static async loadApp() {
        return await AppApi.loadApp();
    }

}

export default AppService;
