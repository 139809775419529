
import {defineComponent} from 'vue';
import SearchWidget from "@/components/widgets/SearchWidget.vue";
import LatestStories from "@/components/stories/LatestStories.vue";

export default defineComponent({
  name: 'SidebarLayout',
  components: {LatestStories, SearchWidget},
  data() {
    return {}
  }
});
